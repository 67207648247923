<template>
    <ClientOnly>
        <Switch
        v-model="enabled"
        :class="enabled ? 'bg-gray-300' : 'bg-gray-300'"
        class="fixed right-16 bottom-12 inline-flex h-10 w-[78px] items-center rounded-full"
        >
            <div>
                <span class="sr-only">Enable notifications</span>
                <span
                :class="enabled ? 'animate-reverse animate-switch' : 'animate-reverse'"
                class="absolute top-0 h-8 w-8 m-1 rounded-full bg-purple align-middle"
                ></span>
            </div>
            <div class="w-full p-3 inline-flex">
                <span class="i-heroicons-sun-solid mr-auto" :class="enabled ? 'bg-gray-700' : 'bg-white'"></span>
                <span class="i-heroicons-moon-solid" :class="enabled ? 'bg-white' : 'bg-gray-700'"></span>
            </div>
        </Switch>
    </ClientOnly>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Switch } from '@headlessui/vue'

const colourMode = useColorMode()
const enabled = ref(colourMode.value === 'dark')
watchEffect(() => useColorMode().preference = enabled.value ? 'dark' : 'light')
</script>